.twilio-call-controls {
    .incall-btn {
        font-size: 15px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 5px;
        margin-top: 10px;
        background: #ffffffd1;
        transition: all 0.25s linear;
        border: none;
        box-shadow: 0px 0px 4px 0px black;

        i {
            font-size: 20px;
            line-height: 48px;
        }
    }

    .vc-buttons {
        width: 400px;
        position: fixed;
        bottom: 15px;
        left: 15px;
        display: flex;
        justify-content: flex-start;
    }

    .connect-call {
        width: auto;
        border-radius: 28px;
        padding: 0px 25px;
        background: #ed193f;
        color: #fff;
        font-weight: 600;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        .end-text {
            margin-left: 8px;
            font-size: 16px;
        }
    }

    .active-btn {
        background: #69686c;
        color: #fff;
        cursor: pointer;
    }

    .mute-sound {
    }
    .mute-video {
    }
    .open-notes {
    }

    .incall-btn:hover {
        background: #1b1b1b;
        color: #fff;
        cursor: pointer;
    }
}

.vc-preview {
    position: absolute;
    bottom: calc(135px - 100vh);
    left: 15px;
}

.video-preview {
    width: 200px;
    border-radius: 12px;
    height: 200px;
    border: white;
    border-width: 1pt;
    object-fit: cover;
    border: 2px solid #fff;
    -webkit-box-shadow: 0px 0px 4px 0px black;
    -moz-box-shadow: 0px 0px 4px 0px black;
    box-shadow: 0px 0px 4px 0px black;
    position: relative;
}

.no-video {
    width: 200px;
    height: 200px;
    border: white;
    border-width: 1pt;
    object-fit: cover;
    background: wheat;
}

.self {
    width: 150px;
    height: 150px;
    border-radius: 12px;
    border: white;
    border-width: 1pt;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    left: 217px;
    top: calc(100vh - 290px);
    -webkit-box-shadow: 0px 0px 4px 0px black;
    -moz-box-shadow: 0px 0px 4px 0px black;
    box-shadow: 0px 0px 4px 0px black;
    z-index: 1;
    border: 2px solid #fff;

    .self-video {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        object-fit: cover;
    }
}

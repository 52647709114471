.mandatory-actions {
    width: calc(100vw - 60px);

    .mandatory-action-item {
        height: 120px;
        border-radius: 10px;
        border: 1px solid #d2d6db;
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        background: #fff;

        .mandatory-action-item-state {
            .circle-state {
                height: 40px;
                width: 40px;
                border: 1px solid #39cf6c;
                border-radius: 50%;
                margin: 0px 0px 0px 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                &.active {
                    background: #39cf6c;
                }
            }
        }
        .mandatory-action-wrap {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 40px;
            .mandatory-action-text {
                .mandatory-action-step {
                    color: #727272;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: uppercase;
                }
                .mandatory-action-title {
                    color: #4c4c4c;
                    font-family: Montserrat;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                .mandatory-action-description {
                    color: #808080;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
            .mandatory-action-button {
                .mandatory-action-completed {
                    color: #39cf6c;
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
        }
    }
}

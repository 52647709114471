html,
body {
    font-family: "Montserrat", sans-serif !important;
}

.login-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    background: no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background: #ffffff;

    .login-page-asset {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;

        video,
        img {
            width: 60vw;
            min-height: 90vh;
            max-height: 90vh;
            object-fit: cover;
            border-radius: 10px;
            margin-left: 5%;
        }

        iframe {
            width: 100vw;
            height: 56.25vw;
            min-height: 100vh;
            min-width: 177.77vh;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .rhs {
        height: 100%;
        display: flex;
        width: 40%;
        padding: 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 2.5%;

        .login-form {
            max-width: 370px;
            .eye-icon {
                float: right;
                margin-top: -32px;
                margin-right: 10px;
                position: relative;
                z-index: 10;
                cursor: pointer;
                color: #999;
            }
        }

        .form {
            display: flex;
            flex-direction: column;
            align-items: center;

            .form-group {
                margin-bottom: 25px;
                align-self: start;
            }

            .btn-primary {
                font-weight: 600;
                width: 100%;
                min-width: 345px;
            }
            .required-field {
                background: #ed193f;
                color: #fff;
                font-size: 11px;
                padding: 5px;
                border-radius: 5px;
                font-weight: 600;
            }
        }

        .language {
            position: absolute;
            right: 10px;
            top: 10px;
        }

        .rememberMe-input {
            margin-right: 10px;
        }

        .login-logo {
            display: flex;
            align-items: center;
            justify-items: center;
            flex-direction: column;

            .welcome {
                font-size: 30px;
                font-weight: 600;
                color: var(--parla-tertiary);
                margin-top: 30px;
                text-align: center;
            }

            .welcome-msg {
                font-family: "Montserrat";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: #727272;
                margin-bottom: 50px;
                text-align: center;
            }
        }

        .rhs-logo {
            height: auto;
            min-height: 50px;
            max-height: 100px;
            max-width: 200px;
            -o-object-fit: contain;
            object-fit: contain;
            margin: 0 auto;
        }

        .form-control {
            width: 100%;
            padding: 15px;
            border-radius: 8px;
            background-color: transparent;
            border: 2px solid #ceddf2;
            color: #4c6280;
            font-size: 100%;
            line-height: 1.15;
            margin: 0;
            font-family: "Montserrat";
            &.is-invalid {
                border-color: #ed193f;
            }
        }

        .form-group {
            label {
                font-size: 14px;
                line-height: 16px;
                color: #4c4c4c;
                text-transform: uppercase;
            }
            .remember-label {
                font-weight: 600;
                font-size: 16px;
                text-transform: unset;
            }
            .form-alert {
                display: none;
                &.is-invalid {
                    display: inline;
                    color: #ed193f;
                    font-weight: 600;
                }
            }
        }

        input:not(input[type="checkbox"]),
        optgroup,
        select,
        textarea {
            font-family: "Montserrat", sans-serif;
            font-size: 100%;
            margin: 0;
            width: 100%;
            max-width: 345px;
            padding: 10px;
        }

        button {
            font-family: "Montserrat", sans-serif;
            font-size: 100%;
            margin: 0;
            width: 100%;
            max-width: 345px;
            padding: 8px;
            border: 2px solid #1e9ce8;
        }
        button:hover {
            background: #fff;
            border: 2px solid #1e9ce8;
            color: #1e9ce8;
        }

        input[type="text"],
        input[type="password"] {
            padding: 14px 18px 14px 18px;
            border-radius: 10px;
            border: 1px solid #808080;
            background: #fff;
            min-width: 345px;
        }

        .reset {
            color: var(--parla-primary);
            font-weight: 600;
            text-decoration: none;
            margin-top: 25px;
        }
    }

    .rhs-footer {
        align-self: flex-end;
        position: absolute;
        bottom: 15px;
        font-size: 13px;
        display: flex;
        width: 100vw;
        white-space: nowrap;
        justify-content: center;
        align-items: center;
        right: 10px;

        .footer-logo {
            padding-left: 10px;
            height: 15px;
        }
    }
}

.password-error {
    color: #ed193f;
    font-size: 16px;
    margin-bottom: 5px;
    text-align: center;
    font-weight: 600;
}

.default-btn:disabled {
    background: #c9c5b6;
    box-shadow: 0 10px 20px rgba(125, 147, 178, 0.5);
}

.registry-signup {
    display: flex;
    flex-direction: column;

    a {
        color: #0d0f38;
        font-weight: 600;
    }
}

@media only screen and (max-width: 990px) {
    .login-wrapper {
        .login-page-asset {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            margin: 0;

            img {
                max-width: 100%;
                min-width: 100vw;
                max-height: 100%;
                min-height: 100vh;
                object-fit: cover;
                margin: 0;
                border-radius: 0;
            }
        }

        .rhs {
            position: absolute;
            background: #ffffff;
            height: calc(100% - 40px);
            width: calc(100% - 40px);
            border-radius: 10px;
            margin: 20px;
            align-items: center;

            .login-logo {
                .welcome {
                    font-size: 22px;
                    margin-top: 20px;
                }

                .welcome-msg {
                    font-size: 13px;
                    margin-bottom: 20px;
                }
            }

            .reset {
                margin-top: 15px;
            }
            .form {
                .form-group {
                    align-self: center;
                }

                .btn-primary {
                    min-width: 300px;
                    max-width: 300px;
                }
                .remember-group {
                    align-self: start;
                }
            }

            input[type="text"],
            input[type="password"] {
                min-width: 300px;
            }
        }
        .rhs-footer {
            bottom: 50px;
        }
    }
}

.modal-overlay {
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100vh;
    .confirmation-modal {
        top: 0;
        width: 100%;
        margin: 10px;
        position: fixed;
        background: #fffffffa;
        max-width: 480px;
        min-height: 26vh;
        box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10%;

        &.login {
            height: fit-content;
            margin: auto;
            top: 0;
            bottom: 0;
            padding: 15px;
            justify-content: space-between;
            .confirmation-modal-header {
                color: #1e9ce8;
                font-size: 20px;
                font-weight: 600;
                line-height: normal;
                text-align: center;
                position: unset;
                cursor: auto;
                padding-top: 15px;
            }
            .confirmation-message {
                color: #102e4a;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                padding: 0;
                display: flex;
                width: calc(100% - 30px);
                text-align: center;
            }
            .confirmation-modal-buttons {
                width: 100%;
                .btn {
                    font-weight: 600;
                    font-size: 14px;
                    padding: 10px;
                    width: 100%;
                    max-width: 100%;
                    margin: 0 3px;
                }
            }
        }

        .confirmation-modal-header {
            position: absolute;
            top: 15px;
            right: 25px;
            font-size: 30px;
            cursor: pointer;
        }
        .close-confirmation-modal {
            position: absolute;
            right: 25px;
        }
        .confirmation-message {
            padding: 2em;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;
            overflow: auto;
            justify-content: center;
            margin: 16px;
            position: relative;
            font-size: 20px;
            color: #647184;
        }

        .modal-footer {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            border-top: none;
            border-bottom-right-radius: calc(0.3rem - 1px);
            border-bottom-left-radius: calc(0.3rem - 1px);
            width: 100%;

            .confirmation-modal-buttons {
                display: flex;
                justify-content: center;

                .default-btn {
                    font-weight: 600;
                    font-family: Montserrat;
                    border-radius: 8px;
                    font-size: 16px;
                    line-height: 16px;
                    padding: 15px 32px;
                    text-align: center;
                    display: inline-block;
                    text-decoration: none;
                    margin-right: 30px;
                    transition: all 0.3s;
                    height: auto;
                    cursor: pointer;
                    background: #9bc4fc;
                    color: #fff;
                    box-shadow: 0 20px 30px rgba(125, 147, 178, 0.3);
                    border: none;
                }

                .btn-confirm {
                    font-weight: 600;
                    font-family: Montserrat;
                    border-radius: 8px;
                    font-size: 16px;
                    line-height: 16px;
                    padding: 15px 32px;
                    text-align: center;
                    display: inline-block;
                    text-decoration: none;
                    margin-right: 30px;
                    transition: all 0.3s;
                    height: auto;
                    cursor: pointer;
                    background: #2f2e2e;
                    color: #fff;
                    box-shadow: 0 20px 30px rgba(125, 147, 178, 0.3);
                    border: none;
                }
            }
        }
    }
}

.navbar-brand {
    line-height: 20px !important;
    margin-right: 0;
}

.nav-logo {
    img {
        height: 25px;
        object-fit: contain;
    }
}

.navbar-toggler {
    padding: 0px 20px;
}

.collapse-group {
    display: flex;
    align-items: center;
    z-index: 16;
}

.navbar-brand-text {
    font-weight: 600;
    color: var(--parla-primary);
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    font-size: 25px;
    padding-left: 15px;
    margin: 0;
    vertical-align: top;
    margin-top: 10px;
    opacity: 1;
}

.header-wrapper {
    margin: 0 auto;
    max-width: 1100px;

    header {
        display: flex;
        flex-flow: row nowrap;
        position: relative;
        text-align: left;
    }

    .btn-outline-info,
    .btn:focus {
        border-color: none !important;
        outline: none !important;
        outline-offset: none !important;
    }
}

.nav-greeting {
    margin-left: auto;
    padding-right: 1.5em;
    color: white;
}

.navbar-nav {
    .nav-option {
        color: var(--TERTIARY-DARK_BLUE, #102e4a);
        font-family: Montserrat;
        font-size: 0.9375rem;
        cursor: pointer;
        padding-left: 1rem;
        font-weight: 600;
        letter-spacing: 0.5px;

        i {
            display: flex;
            width: 2rem;
            height: 2rem;
            padding: 0.4375rem;
            justify-content: center;
            align-items: center;
            border-radius: 1.25rem;
            background: var(--ACCENT-SHADE_OF_DARK_BLUE_1, #eff2f5);
        }

        &.selected {
            font-weight: 600;
            color: var(--parla-secondary);

            .nav-link {
                color: var(--parla-secondary);
            }

            .active {
                color: var(--parla-secondary);
            }
        }

        .nav-link {
            display: flex;
            align-items: center;
            color: #102e4a;
            cursor: pointer;
            border: none;
            padding: 0.625rem 0rem;
            align-items: center;
            gap: 0.5rem;

            &.selected {
                color: var(--parla-secondary);
            }

            .active {
                color: var(--parla-secondary);
            }
        }

        a {
            text-decoration: none;
            color: #102e4a;
        }
    }
}

.nav-content-toggled {
    list-style: none;
    display: contents;
}

.fa-bell {
    padding-right: 2px;
}

.bell-notification {
    z-index: 0;
    position: absolute;
    font-size: 30px;
    right: 10px;
    top: 6px;
    font-weight: 400;
}

.bell-notification[data-count]:after {
    position: absolute;
    right: 4px;
    top: 2px;
    content: attr(data-count);
    font-size: 12px;
    font-family: "Montserrat";
    padding: 1px 3px 2px 3px;
    border-radius: 6px;
    line-height: 1em;
    color: white;
    background: rgb(33, 37, 41);
    text-align: center;
    min-width: 1.2em;
}

.active-bell[data-count]:after {
    background: var(--parla-secondary);
}

.animate-bell {
    transform-origin: 50% 0%;
    animation-name: bellring;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    justify-content: unset !important;
}

.notifications {
    max-height: 406px;
    overflow: auto;
    min-width: 100px !important;
    padding: 0 !important;

    .read-check-btn {
        display: flex;
        color: #ed193f;
        padding: 4px;
        border-radius: 50%;
        font-size: 18px;
        justify-content: center;
        align-items: center;
        min-width: 26px;

        &:hover {
            background: #ed193f;
            color: #fff;
            cursor: pointer;
        }
    }

    .room-notice-stage {
        color: #009688;

        i {
            padding-right: 5px;
        }
    }

    .notifications-empty {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.3em;
        white-space: nowrap;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 14px;
        color: #939393;
    }

    .notification {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ececec;

        &.missed-call {
            font-size: 13px;

            &:hover {
                background: #ffeeee;

                .room-notice-btn .room-notice-from i {
                    background: #e81e1e;
                    color: #fff;
                }
            }

            .room-notice-btn .room-notice-from i {
                transform: rotate(90deg);
                background: #ffeeee;
                color: #e81e1e;
            }

            .room-notice-wrap {
                font-size: 13px;

                .title-wrapper {
                    display: flex !important;
                    gap: 0.4em;
                    margin-bottom: 0;
                }

                strong {
                    display: block;
                    width: 160px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .room-notice-btn {
            background: none;
            padding: 5px 10px;
            width: 100%;
            text-align: left;

            .room-notice-from {
                display: flex;
                align-items: center;

                .room-notice-wrap {
                    color: #102e4a;
                    font-weight: 500;
                    white-space: nowrap;

                    span {
                        display: block;
                        white-space: nowrap;
                    }
                }

                .room-notice-date {
                    color: #727272;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    align-self: start;
                }

                i {
                    min-width: 38px;
                    height: 38px;
                    background: #eef7ff;
                    border-radius: 50%;
                    color: #1e9ce8;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 8px;
                }
            }

            .read-check-btn {
                display: none;
            }
        }

        &:hover {
            background: #eef7ff;

            .room-notice-btn {
                .room-notice-from {
                    i {
                        background: #1e9ce8;
                        color: #fff;
                    }
                }
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }
}

.outer-inlinenotifications {
    flex-direction: row !important;
    display: none !important;

    .notification-icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 1em;
        position: relative;

        .bell-notification::after {
            right: -16px;
            top: -6px;
        }
    }
}

.language-handler {
    .flag {
        font-size: 28px;
        width: 22px;
        height: 22px;
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            background-size: cover;
        }
    }

    .toggle.flag {
        margin-left: -20px;
    }

    .language-option {
        display: flex;
        padding: 5px;
        align-items: center;
    }

    .language-name {
        font-size: 12px;
        padding-left: 5px;
        font-weight: 600;
    }

    .nav-item {
        align-self: center;
    }

    .nav-link {
        display: flex;
        flex-shrink: 0;
        border-radius: 10px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 15px;
        width: 5.3125rem;
        color: #102e4a;
        background: #f8f8f8;
        align-items: center;

        .initials {
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

@media only screen and (max-width: 990px) {
    .outer-inlinenotifications {
        display: flex !important;
    }

    .inner-dropdown-notifications {
        display: none !important;
    }
}

.dropdown-menu .history-wrapper-btn {
    display: block !important;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-weight: 600;
    padding: 2px;
    border-radius: 4px;
    cursor: pointer;
    color: #7d93b2;
    background-color: #e9e9e9;
    text-align: center;

    &:hover {
        color: #282828;
        background-color: #ccc;
    }
}

@keyframes bellring {
    0% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(10deg);
    }

    20% {
        transform: rotate(-10deg);
    }

    30% {
        transform: rotate(10deg);
    }

    40% {
        transform: rotate(-10deg);
    }

    50% {
        transform: rotate(0deg);
    }
}

.dropdown-menu {
    .dropdown-item,
    a {
        color: #272727;
        width: 100%;
        display: block;
        min-width: 170px;
        cursor: pointer;
        text-decoration: none;
        font-size: 13px;
        color: #272727;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;

        &:hover,
        &:active {
            color: unset;
            background-color: unset;
        }

        .dropdown-subtext {
            font-size: 10px;
            margin-bottom: 1px;

            @media only screen and (max-width: 767px) {
                display: none;
            }
        }

        p {
            color: #7d93b2;
        }

        button {
            background: transparent;
            border: none;
            width: 100%;
            display: block;
            padding: 10px 0px;
            min-width: 170px;
            text-align: left;
        }
    }
}

.user-navigation {
    .dropdown-menu {
        .dropdown-item {
            .user-dropdown-name {
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 135px;
                width: 150px;
                overflow: hidden;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        display: none;
    }
}

.dropdown-item-user {
    right: 5px;

    .dropdown-item {
        padding: 8px 20px;
    }
}

.no-toggle {
    .dropdown-toggle {
        &::after {
            display: none;
        }
    }
}

.dropdown-divider {
    width: 80%;
    margin: 0 auto;
}

.overflow-x-clip {
    overflow-x: clip;
}

.hide-in-lg-screen {
    display: none;

    @media only screen and (max-width: 767px) {
        display: block;
    }
}

.hide-in-sm-screen {
    display: block;

    @media only screen and (max-width: 767px) {
        display: none;
    }
}

.headerWrapper {
    .notifications-panel {
        overflow: auto;
        max-height: 500px;
        font-size: 13px;
        color: #272727;
        cursor: pointer;
    }

    .language-handler {
        &.nav-item {
            .nav-link:not(.not-apply):hover {
                border-radius: 0.625rem;
            }

            a {
                &:not(.not-apply) {
                    display: flex;
                    flex-shrink: 0px;
                    font-size: 12px;
                    width: 65px;
                    height: 28px;
                }

                display: flex;
                flex-shrink: 0;
                border-radius: 10px;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 15px;
                width: 5.3125rem;
                color: #102e4a;
                background: #f8f8f8;

                &:hover {
                    border-radius: 0.625rem;
                }

                .fa-chevron-down {
                    height: 10px;
                }
            }

            .dropdown-menu {
                li {
                    cursor: pointer;
                }

                .flag {
                    font-size: 24px;
                    width: 24px;
                    height: 24px;
                }
            }

            li {
                padding: 0.5em;
                display: flex;
                align-items: center;
                width: 100px;

                img {
                    width: 24px;
                    margin-right: 1em;
                }
            }

            width: auto;
        }
    }

    .navbar-collapse {
        justify-content: space-between;
    }

    .collapsing {
        transition: none;
    }

    .nav-item {
        width: 50px;
        align-self: center;

        .nav-link:not(.not-apply, .language-handler) {
            height: 42px;
            width: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #102e4a;
            font-size: 22px;
            transition: 0.3s;

            .fas {
                display: none;
            }

            &:hover,
            :active {
                background: #c9c9c9;
                border-radius: 50%;

                .fas {
                    display: block;
                }

                .fal {
                    display: none;
                }
            }
        }

        .dropdown-menu {
            margin-top: 8px;
            top: 24px !important;
            border: none;
            border-radius: 10px;
            background: var(--ACCENT-WHITE, #fff);
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
            border-radius: 10px !important;
        }

        .dropdown-menu-right {
            right: 10px;

            .call-notification-wrapper {
                border-bottom: 1px solid #ccc;
                white-space: break-spaces;
                font-size: 13px;

                .room-notice-stage {
                    color: #009688;

                    i {
                        padding-right: 5px;
                    }
                }
            }
        }

        .notification {
            padding: 5px 10px;

            .caller {
                span {
                    font-weight: 600;
                }
            }

            .journey {
                span {
                    font-weight: 600;
                }
            }

            .owner {
                span {
                    font-weight: 600;
                }
            }
        }

        .nav-profile-picture {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            object-fit: cover;
        }

        .user-dropdown-role {
            font-size: 13px;
            font-weight: 500;
            color: #aaaaab;
            cursor: default;
            margin-bottom: 0px;
        }

        .user-dropdown-name {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0px;
            color: #272727;
        }

        .header-dropdown-name {
            padding-left: 12px;
            padding-top: 8px;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 0px;
            color: #272727;
        }
    }

    .mobile-user-profile {
        display: none;
        padding: 4px 16px;
        cursor: pointer;
        text-decoration: none;
        border-radius: 10px;
        background: var(--ACCENT-SHADE_OF_WHITE_1, #f8f8f8);
        margin: 0 1em;

        .nav-user-profile {
            width: 100% !important;
            padding: 5px 0;

            .nav-profile-picture {
                width: 60px;
                height: 60px;
            }

            .nav-profile-info-wrap {
                .nav-user-profile-name {
                    display: block !important;
                    max-width: 100% !important;
                }
            }

            .fas.fa-caret-down {
                display: none;
            }
        }

        @media only screen and (max-width: 767px) {
            display: flex;
        }
    }

    .nav-user-profile {
        display: flex;
        width: 160px;
        align-items: center;

        .nav-profile-picture {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;

            &.fas {
                background: #102e4a;
                height: 36px;
                width: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                color: #fff;
            }
        }

        .nav-profile-info-wrap {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            width: 100%;

            .nav-user-profile-name {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                color: #102e4a;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 90px;
                overflow: hidden;
            }

            .nav-user-profile-role {
                color: #4c4c4c;
                font-size: 12px;
                font-weight: 500;
            }
        }

        i {
            color: #102e4a;
            padding: 0 10px;
            display: flex;
            align-items: center;
        }
    }
}

.settings-nav {
    .settings-nav-item {
        svg,
        i {
            display: none;
        }
    }

    .dropdown-divider {
        width: 100%;
    }

    @media only screen and (max-width: 767px) {
        .header-dropdown-name {
            padding-left: 16px !important;
            color: var(--TERTIARY-DARK_BLUE, #4c4c4c) !important;
            font-size: 18px !important;
            font-weight: 700 !important;
        }

        .nav-link {
            display: none !important;
        }

        .mobile-menu {
            display: block !important;
            border: none;
        }

        .dropdown-divider {
            display: none;
        }
    }
}

.mobile-header.menu {
    display: none;
    align-items: center;
    justify-content: space-between;
    color: var(--TERTIARY-DARK_BLUE, #102e4a);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 1rem;

    @media only screen and (max-width: 767px) {
        display: flex;
    }

    button {
        padding: 0;

        .fa-times {
            font-weight: 100;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 38px;
            height: 38px;
            flex-shrink: 0;
            border-radius: 20px;
            background: var(--ACCENT-SHADE_OF_DARK_BLUE_1, #eff2f5);
        }
    }

    & ~ .navbar-options {
        .nav-link i {
            display: none;

            @media only screen and (max-width: 767px) {
                display: flex !important;
            }
        }

        @media only screen and (max-width: 767px) {
            .nav-option.selected {
                .nav-link {
                    color: var(--TERTIARY-DARK_BLUE, #102e4a);
                }

                i {
                    color: #fff;
                    background-color: #102e4a;
                }

                background: var(--ACCENT-SHADE_OF_DARK_BLUE_1, #eff2f5);
            }
        }
    }
}

.mobile-header.sub-menu {
    display: none;
    color: var(--TERTIARY-DARK_BLUE, #4c4c4c) !important;
    font-size: 18px !important;
    font-family: Montserrat;
    font-weight: 700;
    padding: 12px 16px;
    margin-top: 12px;

    &.support {
        padding: 8px 16px 12px 16px;
        margin-top: 0px;
    }

    @media only screen and (max-width: 767px) {
        display: flex;
    }
}

.fullscreen-notification {
    background-color: #fff;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    z-index: 9999;

    .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--ACCENT-SHADE_OF_DARK_BLUE_2, #d2d6db);
        background: var(--ACCENT-WHITE, #fff);
        margin-top: 8px;

        > span {
            display: flex;
            justify-content: center;
            min-width: 50%;
            margin-bottom: -1px;
            padding: 14px 0;

            &.selected {
                color: #f40e6f;
                border-bottom: 2px solid var(--SECONDARY-PINK, #f40e6f);
                background: var(--ACCENT-WHITE, #fff);
            }

            .notification-count {
                font-size: 11px;
                font-weight: 700;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                background: var(--SECONDARY-PINK, #f40e6f);
                color: var(--ACCENT-WHITE, #fff);
                border-radius: 50%;
                margin-left: 6px;
            }
        }
    }

    .notification {
        padding: 16px 24px;

        a,
        .nav-link,
        .room-notice-btn {
            padding: 0;
        }
    }

    .notifications-empty {
        padding: 16px 24px;
    }
}

@media only screen and (max-width: 990px) {
    .app-wrapper {
        .env-banner {
            transform: unset;
            bottom: 0;
            top: unset;
            left: 0;
            width: 35px;
            padding: 4px;
            font-size: 8px;
        }
    }

    .navbar-light {
        .navbar-toggler {
            padding: 0 20px;
            border-color: transparent !important;

            &:focus {
                box-shadow: none;
            }
        }
    }

    .full-menu {
        width: 100vw;
    }

    .headerWrapper {
        .show:not(.nav-item) {
            justify-content: space-between;
            background: #fff;
            position: absolute;
            z-index: 16;
            top: 0px;
            right: 0px;
            border-radius: 4px;
            display: block;
            max-height: 100vh;
            overflow: auto;
        }

        .only-notifications {
            .show:not(.nav-item) {
                right: -6em;
                max-width: calc(100vw - 0.8em);
            }
        }

        .call-notification {
            .show:not(.nav-item) {
                right: -3.3em;
                max-width: calc(100vw - 0.8em);
            }
        }

        .nav-item {
            width: 100%;
            display: none;

            &:not(.nav-link) {
                display: flex;
                flex-direction: column;
            }

            .nav-link {
                width: 100%;

                &:hover {
                    background: var(--parla-primary);
                    border-radius: 0;
                    color: #fff;
                }
            }
        }

        .inner-nav {
            .nav-link {
                position: relative;
            }

            .dropdown-menu {
                box-shadow: none;
                width: 100%;
                height: 100%;
                display: contents;

                &:not(.show) {
                    display: none;
                }
            }

            .dropdown-menu {
                box-shadow: none;
                width: 100%;
                height: 100%;
                display: contents;

                &:not(.show) {
                    display: none;
                }
            }
        }

        .nav-option {
            &.selected {
                color: #fff;
                background: var(--parla-primary);
                border-bottom: none;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    body {
        .navbar {
            max-height: 40px !important;
            min-height: 40px !important;
            --bs-navbar-padding-y: 0rem;

            .menu-icon-tags {
                display: block !important;
                position: absolute;
                left: 50px;
                font-size: 16px;
            }

            .settings-nav-item,
            .user-nav-item {
                color: var(--TERTIARY-DARK_BLUE, #102e4a);
                font-family: Montserrat;
                font-size: 0.9375rem;
                font-weight: 600;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 0.625rem 0rem;
                gap: 0.5rem;

                i,
                svg {
                    display: flex;
                    width: 2rem;
                    height: 2rem;
                    padding: 0.4375rem;
                    justify-content: center;
                    align-items: center;
                    border-radius: 1.25rem;
                    background: var(--ACCENT-SHADE_OF_DARK_BLUE_1, #eff2f5);
                }
            }

            .user-nav-item {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }

    .nav-logo img {
        height: auto;
        width: 100px;
        padding-left: 15px;
    }

    // .headerWrapper .show:not(.nav-item),
    // .headerWrapper .show:not(.language-handler) {
    //     top: 40px;
    // }

    .livestream-page .livestream-page-content .remote {
        height: 130px;
    }
}

@media only screen and (max-height: 658px) {
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
        max-height: 415px;
        overflow: auto;
        height: 100vh;
    }
}

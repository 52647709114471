.permission-panel {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .permission-panel-inner-wrap {
        overflow: auto;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }
}
@media only screen and (max-height: 400px) {
    .permission-panel {
        .permission-panel-inner-wrap {
            justify-content: unset;
        }
    }
}
.permission-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    align-items: center;
    height: 60px;

    &:nth-child(odd) {
        background: #f3f3f3;
        padding: 3px 0;
    }
    &:nth-child(even) {
        background: #ffffff;
        padding: 3px 0;
    }
}
.permission-target {
    text-transform: capitalize;
    padding: 5px;
}
.permission-level {
    text-align: right;
    font-size: 12px;
    display: flex;
    align-items: center;
    div {
        border-radius: 10px;
        color: #102e4a;
        background: #deefff;
        padding: 8px 20px;
        margin-left: 10px;
    }
}
.confirmation-modal-buttons {
    display: flex;
    margin-bottom: 15px;

    .default-btn {
        font-weight: 600;
        font-family: Montserrat;
        border-radius: 8px;
        font-size: 16px;
        line-height: 16px;
        padding: 15px 32px;
        text-align: center;
        display: inline-block;
        text-decoration: none;
        transition: all 0.3s;
        height: auto;
        cursor: pointer;
        background: #2f2e2e;
        color: #fff;
        border: none;
    }
    .btn-confirm {
        font-weight: 600;
        font-family: Montserrat;
        border-radius: 8px;
        font-size: 16px;
        line-height: 16px;
        padding: 15px 32px;
        text-align: center;
        display: inline-block;
        text-decoration: none;
        transition: all 0.3s;
        height: auto;
        cursor: pointer;
        background: var(--parla-primary);
        color: #fff;
        border: none;
    }
}

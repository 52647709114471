.grid-layout {
    position: relative;
    transition: height 0.2s ease;

    .grid-parent {
        .card {
            flex: 1 1 auto;
            margin-bottom: 15px;
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border: 1px solid #dee2e6;
            border-radius: 0.25rem;

            .card-header {
                padding: 0.75rem 1.25rem;
                margin-bottom: 0;
            }

            .card-body {
                flex: 1 1 auto;
                padding: 1.25rem;

                .input-group {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: stretch;
                    width: 100%;
                }
            }

            .card-footer {
                padding: 0.75rem 1.25rem;
                background-color: rgba(0, 0, 0, 0.03);
                border-top: 1px solid #dee2e6;
            }
        }
    }

    .grid-1 {
        width: 380px;
        height: 330px;
        position: absolute;
        touch-action: none;
        transform: translate(0px, 0px);
    }

    .grid-2 {
        width: 380px;
        height: 330px;
        position: absolute;
        touch-action: none;
        transform: translate(380px, 0px);
    }

    .grid-3 {
        width: 380px;
        height: 330px;
        position: absolute;
        touch-action: none;
        transform: translate(760px, 0px);
    }
}

.data-veiw-footer {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    padding: 0.75rem;
    direction: rtl;
    z-index: 9;

    .btn {
        margin: 3px;
        display: flex;
        min-width: 40px;
        min-height: 38px;
        transition: 0.3s;
        align-items: center;
        justify-content: center;

        span {
            opacity: 0;
            display: none;
            transform: translateX(0px);
        }

        &:hover {
            width: auto;
            flex-direction: row;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                opacity: 1;
                display: block;
                transform: translateX(-10px);
                padding-left: 15px;
            }
        }
    }
}

.asset-image {
    max-height: 20em;
}

[contenteditable]:hover {
    outline: 0px solid transparent;
    background-color: #89cf89;
}

[contenteditable]:focus:not(:hover) {
    outline: 0px solid transparent;
    background-color: #89cf89;
}

[contenteditable]:focus {
    outline: 0px;
    background-color: #89cf89;
}

[contenteditable] {
    padding: 3px;
    border-radius: 3px;
}

[contenteditable][placeholder]:empty:before {
    content: attr(placeholder);
    position: absolute;
    color: gray;
    background-color: transparent;
}

.radio-group {
    display: flex;

    .radio {
        margin-right: 15px;
        cursor: pointer;
    }

    // label>input[type="radio"] {
    //     display: none;
    // }

    // label>input[type="radio"]+*::before {
    //     content: "";
    //     display: inline-block;
    //     width: 1rem;
    //     height: 1rem;
    //     margin-right: 0.3rem;
    //     border-radius: 50%;
    //     border-style: solid;
    //     border-width: 0.1rem;
    //     border-color: gray;
    // }

    // label>input[type="radio"]:checked+*::before {
    //     background: radial-gradient(teal 0%, teal 40%, transparent 50%, transparent);
    //     border-color: rgb(0, 106, 106);
    // }

    // label>input[type="radio"]:checked+* {
    //     color: teal;
    // }
}

.notifications-row {
    display: flex;
}

.custom-breadcrumb {
    list-style-type: none;
    padding: 0;
    display: inline-flex;

    .custom-breadcrumb-item {
        display: inline-block;
        position: relative;
        margin: 2px;

        // Last child
        // &:last-child {
        //     a {
        //         cursor: default;
        //     }

        //     &::before,
        //     &::after {
        //         background: #92fbf16b;
        //     }
        // }

        // :Hover
        &:hover {

            &::before,
            &::after {
                background: #d0e0ff;
            }
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
            height: 50%;
            width: 100%;
            background: white;
            border-left: 3px solid #666;
            border-right: 3px solid #666;
            z-index: 0;
        }

        &::before {
            top: 0px;
            transform: skew(30deg);
            border-top: 2px solid #666;
        }

        &::after {
            bottom: 0px;
            transform: skew(-30deg);
            border-bottom: 2px solid #666;
        }
    }

    span {
        display: inline-block;
        position: relative;
        line-height: 2.5;
        padding: 0 20px;
        color: #666;
        text-decoration: none;
        font-weight: 600;
    }

    span:hover {
        cursor: pointer;
    }

    .custom-breadcrumb-item {
        &:first-child {
            background-color: white;
            border-left: 2px solid #666;
            left: -5px;
            box-sizing: content-box;
            z-index: 22;

            &:hover {
                background: #d0e0ff;
            }

            &::before,
            &::after {
                left: 5px;
                border-left: none;
            }
        }

        span {
            z-index: 20;
            position: relative;
        }
    }
}

.left-50,
.right-50 {
    width: 50%;
    padding: 5px;
}

.published-toggle {
    float: right;
    position: relative;
    display: flex;
    padding: 2px;
    z-index: 0;

    label {
        padding-right: 2px;
    }

    .toggle-button {
        margin-bottom: 0.5rem;
    }
}

.title-editor {
    z-index: 1;
}

.inline-editor-label {
    font-size: 20px;
}

.droppable-container {
    display: flex;
}

.tenant-view {
    .asset-selector {
        margin: 15px 0;
    }

    .container {
        margin: 0;
        padding: 25px;
    }
}

.asset-selector {
    display: flex;
    overflow-x: auto;

    .draggable-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        i {
            position: relative;
        }
    }

    .modal-wrapper {
        .asset-modal {
            width: 90%;
        }
    }

    .section-card-group {
        .card-group {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 250px;
            height: 350px;
            border: 1px solid #ccc;
            border-radius: 5px;
            flex-direction: column;
            cursor: pointer;
            overflow: hidden;
            margin-left: 5px;

            .card-quote {
                padding: 5px;
                font-size: 8px;
                width: 120px;
            }
        }
    }

    .card-group {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
        border: 1px solid #ccc;
        border-radius: 5px;
        flex-direction: column;
        cursor: pointer;
    }

    .asset-list {
        border: 1px solid #ccc;
        border-radius: 8px;
        padding: 15px;
        margin: 10px 0;
        display: flex;
        flex-wrap: wrap;

        .asset-list-card {
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 4px 10px;
            margin: 2px 0;
            color: #333;
            font-size: 14px;
            cursor: pointer;

            &:hover {
                background-color: #ccc;
                font-weight: 600;
            }
        }

        .active {
            box-shadow: 0 0 0 3px var(--parla-primary);
            border-color: transparent;
        }
    }
}

.product-assets {
    max-height: 800px;

    .nested-product-asset-selector {
        overflow-x: scroll;
        display: flex;
        padding: 0;

        li {
            list-style: none;
            margin: 0 8px 8px 0;
        }

        // li:last-child {
        //     flex-grow: 10;
        // }

        // li:first-child {
        //     flex-grow: 10;
        //     columns: 10em;
        //     flex-grow: 2;
        // }
    }

    // .product-view-panel-group {}

    .big-asset-view {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        max-height: 650px;
        max-width: 650px;
        margin: auto;
        padding-bottom: 25px;
    }

    .big-asset-view img {
        width: 100%;
    }
}

.journey-metrics {
    float: right;
    margin-top: -50px;
    margin-right: -35px;

    .nav-link {
        font-weight: 600;
        color: #000;
    }

    .nav-link:hover {
        color: var(--parla-primary);
    }

    .dropdown-menu {
        margin-right: 15px;

        .dropdown-item {
            padding: 0;

            button {
                padding: 10px 20px;
                border: none;
                background: transparent;
                color: gray;
                font-size: 14px;
                width: 100%;
                text-align: left;
            }

            button:hover {
                color: #fff;
                background: #cec3fc;
            }
        }
    }
}

.page-tab {
    color: #102e4a;
    cursor: pointer;
    font-size: 13px;

    &.active {
        border-bottom: 2px solid var(--parla-secondary) !important;
        font-weight: 600;
        color: var(--parla-secondary) !important;
    }
}

.copy-commands {
    .embedded-journey-clipboard-copy {
        cursor: pointer;
    }

    .copied-success {
        transition: 0.5s ease-out 0.5s ease-in;
        color: rgb(44, 157, 34);
    }
}

.customer-journey-row {
    display: grid;
}

.customer-view,
.user-view {
    .tabs-container {
        margin: auto;
        margin-top: 0px;
        margin-bottom: 50px;
        margin-left: 15px;

        .tab-content {
            height: calc(100% - 40px);
            border: 1px solid #dee2e6;
            border-top: none;
            padding: 15px;
            background: #fff;
        }
    }

    .customer-orders {
        .order-body {
            width: 100%;
        }
    }

    .customer-profile-top,
    .user-profile-top {
        display: flex;
        margin-bottom: 30px;

        .uploader-component {

            label,
            small {
                display: none;
            }

            .file-button-wrap {
                label {
                    display: block;

                    i {
                        padding-right: 10px;
                    }
                }
            }
        }

        .left {
            width: 250px;
            display: flex;
            flex-direction: column;

            .customer-pic {
                border-radius: 18px;
                overflow: hidden;
                width: 150px;
                height: 150px;
                margin-bottom: 15px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    object-fit: cover;
                    min-width: 100%;
                    min-height: 100%;
                }

                i {
                    font-size: 140px;
                    color: #ccc;
                }
            }

            .customer-profile-name {
                font-weight: 600;
                font-size: 20px;
                padding-bottom: 10px;
            }

            .customer-profile-email,
            .customer-profile-phone {
                text-align: left;
                font-size: 14px;
                font-weight: 600;
            }

            .customer-profile-analytics {
                font-size: 10px;
                background: #fff;
                border: 1px solid #999;
                border-radius: 4px;
                padding: 3px;
                margin-bottom: 3px;
            }

            .label {
                font-size: 12px;
                color: #787878;
                padding-top: 10px;
                font-weight: 600;
            }

            .uploader-component {
                display: none;
            }

            .customer-tags {
                .form-group {
                    display: flex;
                    flex-direction: column;
                }

                .col-form-label {
                    font-size: 12px;
                    color: #787878;
                    padding-top: 10px;
                    font-weight: 600;
                }

                .input-tag {
                    border: none;
                }

                .input-tag__tags__input {
                    margin-top: 15px;
                }
            }

            .customer-profile-associate-wrap {
                display: flex;
                align-items: center;
                margin-top: 10px;

                .customer-profile-associate-img {
                    border-radius: 50%;
                    overflow: hidden;
                    margin-right: 10px;
                }

                .customer-profile-associate-name {
                    font-weight: 600;
                    font-size: 15px;

                    span {
                        margin-right: 10px;
                        color: #787878;
                    }
                }

                .fa-user {
                    padding: 3px;
                    color: #999;
                }
            }
        }

        .right {
            width: calc(100% - 250px);
            border-radius: 13px;

            .msg-board {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .created {
                    margin: 4px;
                    font-size: 10px;
                    color: #5b5a5a;

                    .sender {
                        font-weight: 600;

                        i {
                            padding-right: 3px;
                        }
                    }
                }

                .send-message,
                .send-note {
                    display: flex;
                    align-items: center;

                    .message-body,
                    .note-body {
                        background: #e9e9e9;
                        padding-left: 14px;
                        border-radius: 30px;
                        border: none;
                        width: 100%;
                        color: #626262;
                        font-size: 15px;
                        height: 34px;
                    }

                    button {
                        color: white;
                        border: none;
                        background: var(--parla-primary);
                        border-radius: 50%;
                        height: 34px;
                        width: 34px;
                        text-align: center;
                    }
                }

                .msg-board-header {
                    display: flex;
                    padding: 2px 8px 0;

                    .msg-header-left {
                        width: 50%;
                        font-weight: 600;
                    }

                    .msg-header-right {
                        font-weight: 600;
                        width: 50%;
                        text-align: end;
                        color: rgb(88, 140, 239);
                    }
                }

                .reader {
                    overflow: auto;
                    position: relative;
                    overflow-anchor: none;
                    height: 100%;

                    .reader-board {
                        display: flex;
                        flex-direction: column-reverse;
                        padding-left: 35px;
                        overflow: auto;
                        width: 100%;
                        height: calc(100vh - 465px);
                    }

                    &:after {
                        content: "";
                        display: block;
                        overflow-anchor: auto;
                        height: 1px;
                    }
                }

                .msg {
                    max-width: fit-content;
                    margin-bottom: 8px;
                    border-radius: 8px;
                    padding: 4px 10px;
                    font-size: 15px;
                    line-height: 16px;
                    border: 1px solid #ccc;
                    align-self: flex-end;
                    margin-right: 10px;
                    position: relative;
                }

                .msg-customer {
                    word-break: break-word;
                    align-self: flex-start;
                    text-align: right;
                }

                .channelType {
                    position: absolute;
                    left: -28px;
                    height: inherit;
                    border: 1px solid #ccc;
                    border-radius: 50%;
                    padding: 10px;
                    background: #fff;
                    color: #f34199;
                }

                .msg-me {
                    font-size: 13px;
                    background-color: #f4f4f4;
                }

                .msg-admin {
                    font-size: 13px;
                }

                .input-send-msg {
                    display: flex;
                    justify-content: space-between;
                    align-items: baseline;

                    a {
                        position: relative;
                        background: #b1b1b1;
                        border-radius: 50%;
                        margin-top: -32px;
                        height: 28px;
                        width: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 13px;
                    }
                }
            }

            .customer-setting {
                width: 100%;

                .form-group {
                    width: 100%;

                    .multi-select {
                        width: 350px;
                    }
                }
            }
        }

        .right {
            width: 100%;
        }

        .nav-tabs {
            display: flex;
            white-space: nowrap;

            .nav-link {
                min-width: inherit;
                width: initial;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 40px;
            }
        }
    }

    .customer-profile-details {
        display: flex;
        flex-direction: column;

        h2 {
            margin: 0px 0px 20px 5px;
            font-weight: 600;
        }

        .form-group {
            .form-group {
                margin-bottom: 0;
            }
        }

        .customer-setting {
            >div {
                width: 100%;
            }

            .multi-select {
                width: 100%;
            }
        }

        .customer-location {
            >div {
                width: 100%;
            }
        }

        .customer-location-input {
            width: 100%;
        }

        .customer-sep {
            width: 100%;
            height: 1px;
            background: #cfcfcf;
            margin: 20px 0;
        }
    }

    .inline-editor-label {
        font-size: 16px;
    }

    .address {
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.125);
        font-size: 12px;
        font-weight: 600;
        border-radius: 0.25rem;
        padding: 3px 5px;
    }

    .address-card {
        position: relative;
        margin: 0 0 20px 0;

        .remove-address {
            position: absolute;
            top: 0px;
            right: 0px;
            margin: 10px;

            button {
                margin-right: 5px;
            }
        }

        .address-default-label {
            border-radius: 4px;
            font-size: 14px;
            color: #787878;
            margin-left: 20px;
            padding: 0px 2px;
            font-weight: 600;
            background: var(--parla-primary);
            color: #fff;
            padding: 2px 6px;

            span {
                display: contents;
            }

            i {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 900px) {
        .address-card {
            .remove-address span {
                display: none;
            }
        }
    }

    .address-details {
        padding: 8px 12px;
        font-size: 14px;

        label {
            color: hsl(0, 0%, 55%);
            font-weight: 400;
            padding-right: 2px;
            width: 80px;
        }
    }

    .no-name-address {
        color: #ccc;
        font-weight: 600;
    }

    .mini-address-card {
        font-size: 13px;
        line-height: 15px;
        margin-top: 15px;
    }

    .mini-address-card-item {
        word-break: break-word;

        label {
            margin-right: 6px;
            margin-bottom: 0rem;
        }
    }

    .form-group {
        display: flex;

        label {
            white-space: nowrap;
            padding-right: 15px;
            margin-top: 7px;
        }
    }

    .input-group-prepend {
        font-size: 20px;
        padding: 5px;
    }

    .related-journeys,
    .tab-pane {
        position: relative;

        .visits-mini-cards-wrapper {
            display: flex;
            justify-content: center;
            padding: 15px 0 0;
            border-bottom: 1px solid #d9e3ed;
            margin-bottom: 15px;

            .visits-mini-card {
                border-radius: 8px;
                min-height: 100px;
                height: 100%;
                margin-bottom: 10px;
                padding: 10px;
                width: 100%;
                max-width: 270px;
                margin-right: 15px;
                font-size: 14px;
                font-weight: 600;
                color: #ffffff;

                &:last-child {
                    margin-right: 0px;
                }

                .visits-mini-card-value {
                    font-size: 38px;
                    line-height: 38px;
                    color: #fff;
                }

                .visits-mini-card-list {
                    color: #fff;
                }

                .visits-mini-card-list-wrapper {
                    display: flex;
                    justify-content: space-between;

                    .right {
                        text-align: right;
                    }

                    .visits-mini-card-list {
                        line-height: 14px;
                        font-size: 13px;
                        color: #fff;
                    }
                }

                .add-new-order {
                    font-size: 24px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding-top: 15px;
                    cursor: pointer;
                }
            }
        }

        .accordion {
            .card {
                margin-bottom: -2px;
            }
        }

        .action-button {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }

    .crm-summary-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .interest-chevron {
            display: flex;
            justify-content: space-between;
            align-content: center;
            width: 50%;

            i {
                align-self: center;
            }
        }

        .crm-header-analytic-list {
            list-style: none;
            display: flex;
            margin: 0;

            >li {
                padding-left: 25px;
                font-weight: 600;
                color: #5e5e5f;

                i {
                    padding-right: 5px;
                }
            }
        }

        .crm-header-journey-name,
        .crm-header-access-code,
        .crm-header-date,
        .crm-header-analytics,
        .crm-purchase-total {
            flex-grow: 1;
            flex-basis: calc(100% / 5);
            font-size: 14px;

            i {
                padding-right: 5px;
                color: #5e5e5f;
            }
        }

        .crm-header-journey-name {
            font-weight: 600;
            font-size: 16px;
        }

        .crm-purchase-total {
            text-align: right;
            padding-right: 15px;
        }
    }

    .accordion {
        cursor: pointer;

        .card-header {
            background: #fff;
        }
    }

    .order-header {
        display: flex;
        justify-content: space-between;
    }

    .accordion-body {
        display: flex;
        justify-content: space-between;
        padding: 0;

        .order-card {
            width: 100%;

            .order-body {
                padding-right: 0;

                .order-status {
                    background: #ffc107;
                }
            }
        }

        .linked-favourited-products,
        .linked-purchased-products,
        .linked-addCart-products {
            display: flex;
            flex-flow: column;
            justify-content: space-around;
            margin-right: 25px;
        }
    }

    .crm-appointment-notes {
        display: flex;
        flex-direction: column;
    }

    .crm-accordion-analytics {
        min-width: 365px;
        display: flex;
        flex-direction: column;
        white-space: pre;

        .crm-accordion-label {
            align-self: center;
            padding-bottom: 15px;
        }

        .crm-accordion-analytics-list {
            list-style: none;
            padding: 0;

            li {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding-bottom: 15px;

                .left {
                    text-align: left;
                }

                .right {
                    text-align: right;
                }
            }
        }
    }

    .customer-purchased-liked-products {
        display: flex;
        overflow: auto;
        padding-left: 1px;
        padding-bottom: 6px;
        flex-direction: column;

        .dashboard-product-card {
            max-width: 120px;
            text-overflow: ellipsis;
            overflow: hidden;

            .attributes-label {
                display: flex;
                justify-content: center;
                text-align: center;
                font-size: 11px;
                color: #505050;
            }
        }

        .text-center {
            white-space: nowrap;
        }
    }
}

.reader-board {
    height: auto;

    .msg-admin {
        align-self: end;
    }

    .msg-customer {
        align-self: start;
    }
}

.ai-crm-summary {
    font-size: 13px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin: 5px 0;

    .ai-result {
        margin: 8px 0px;

        ul {
            margin-bottom: 0px;
        }
    }

    .think {
        display: flex;
        align-items: center;
        margin-top: 10px;
        font-size: 16px;
    }

    .think-icon {
        position: relative;
        margin-left: 10px;
        width: 20px;
        height: 20px;
    }

    .think-icon i {
        position: absolute;
    }

    .fa-brain {
        color: lightgray;
    }

    .fa-search {
        animation: myAnim 2s ease-in-out 0s infinite normal forwards;

        @keyframes myAnim {
            0% {
                transform: translate(3px, -7px);
            }

            25% {
                transform: translate(13px, 3px);
            }

            50% {
                transform: translate(3px, 13px);
            }

            75% {
                transform: translate(-7px, 3px);
            }

            100% {
                transform: translate(3px, -7px);
            }
        }
    }

    .ai-crm-summary-title {
        background: #fff;
        margin-top: -20px;
        font-weight: 600;
        width: fit-content;
        padding: 0 10px;
        margin-bottom: 2px;
    }
}

.page-header {
    display: block;
    margin: 25px auto;
    width: 90vw;

    .page-title {
        color: #102e4a;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .page-subtitle {
        color: #102e4a;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.cta-page {
    display: flex;
    flex-direction: column;
    width: 90vw;
    margin: 25px 5vw;
    height: calc(100vh - 12em);

    iframe {
        width: 100%;
        height: 100%;
    }
}

.profile-page {
    display: flex;
    width: 90vw;
    margin: 25px 5vw;

    .user-view-card {
        width: 400px;
        margin-right: 15px;
        background: #fff;
        padding: 15px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        .uploader-component {
            .custom-file-upload {
                border: none;
                display: flex;
                cursor: pointer;
                border-radius: 10px;
                font-size: 14px;
                font-weight: bold;
                color: #fff;
                background: linear-gradient(91deg, #1e9ce8 0.11%, #f40e6f 100%);
                width: 100%;
                height: 48px;
                justify-content: center;
                align-items: center;
                position: unset;

                i {
                    margin-right: 5px;
                }
            }
        }

        .form-group {
            color: #4c4c4c;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
            padding: 15px 0 5px;

            input,
            .PhoneInputCountry {
                margin: 5px 0;
                font-size: 14px;
                padding: 8px 15px;
                border: 1px solid #808080;
                border-radius: 8px;
                box-shadow: none;
                color: #102e4a;
            }

            .PhoneInputCountry {
                margin-right: 5px;
            }

            .PhoneInputInput {
                color: #102e4a;
                font-size: 14px;
            }

            .PhoneInputCountryIcon--border {
                box-shadow: none;
            }
        }

        .created-updated {
            display: flex;
            flex-wrap: wrap;

            .created-updated-tag {
                border-radius: 10px;
                background: #eff2f5;
                font-size: 12px;
                text-transform: capitalize;
                display: flex;
                padding: 15px;
                width: 100%;
                margin: 5px 0;
                line-height: 12px;
                color: #102e4a;

                label {
                    padding-right: 5px;
                }

                .read-only-data {
                    font-weight: 400;
                }
            }
        }
    }

    .user-view-settings {
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }

        .user-view-settings-panel {
            background: #fff;
            padding: 15px;
            margin-bottom: 15px;
            border-radius: 10px;

            .teams {
                display: flex;
                flex-wrap: wrap;

                .user-profile-tenant-card {
                    margin-right: 25px;

                    div,
                    .place-holder {
                        border-radius: 8px;
                        border: 1px solid #d2d6db;
                        background: #fff;
                        height: 80px !important;
                        width: 80px !important;
                        padding: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        object-fit: contain;

                        img {
                            object-fit: contain;
                        }
                    }

                    .place-holder {
                        filter: grayscale(100%);
                        opacity: 0.5;
                    }

                    label {
                        color: #102e4a;
                        font-size: 12px;
                        text-align: center;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: capitalize;
                        width: 100%;
                    }
                }
            }

            label {
                color: #4c4c4c;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                padding: 0px 0 10px;
            }

            .user-profile-role {
                background: #ffe7f1;
                border-radius: 10px;
                color: #f40e6f;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                padding: 10px;
                width: 180px;
            }
        }

        .timezone-locale {
            display: flex;
            justify-content: space-between;

            .css-13cymwt-control,
            input {
                border: 1px solid #808080;
                font-size: 14px;
                color: #102e4a;
            }
        }
    }

    .user-profile-reset-password {
        border-radius: 10px;
        background: #102e4a;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        margin-top: 15px;
        padding: 13.5px;
        flex-direction: column;

        p {
            margin: 0;
        }
    }
}

.user-disable-card {
    display: flex;
    width: 100%;
    background: rgba(33, 150, 243, 0.1607843137) !important;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    height: 48px;

    .user-profile-disable-txt {
        padding-left: 10px;
        font-size: 14px;
        color: #1e9ce8;
        font-weight: 600;
    }

    .published-toggle {
        display: flex;
        align-items: center;
        padding-right: 10px;

        label {
            padding: 0 !important;
            margin-right: 10px;
        }

        .toggle-button {
            margin-bottom: 0;
        }
    }
}

.user-view-settings-panel.name-email {
    display: flex;

    .form-group {
        width: 100%;

        &:first-child {
            margin-right: 15px;
        }

        input {
            margin: 5px 0;
            font-size: 14px;
            padding: 8px 15px;
            border: 1px solid #808080;
            border-radius: 8px;
            box-shadow: none;
            color: #102e4a;
        }
    }
}

.user-view-settings-panel.phone-role {
    display: flex;

    .form-group {
        width: 100%;
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }
    }

    input,
    select,
    .PhoneInputCountry {
        margin: 5px 0;
        font-size: 14px;
        padding: 8px 15px;
        border: 1px solid #808080;
        border-radius: 8px;
        box-shadow: none;
        color: #102e4a;
    }

    .PhoneInputCountry {
        margin-right: 5px;
    }

    .PhoneInputInput {
        color: #102e4a;
        font-size: 14px;
    }

    .PhoneInputCountryIcon--border {
        box-shadow: none;
    }
}

.user-view-settings-panel.timezone-locale {
    display: flex;

    .form-group {
        &:first-child {
            width: 100%;
            margin-right: 15px;
        }

        &:last-child {
            width: 250px;
            margin-right: 0;
        }

        input {
            margin: 5px 0;
            font-size: 14px;
            padding: 8px 15px;
            border: 1px solid #808080;
            border-radius: 8px;
            box-shadow: none;
            color: #102e4a;
        }
    }

    .css-13cymwt-control {
        border-radius: 8px;
        margin-top: 5px;
    }
}

.one-to-one-call {
    z-index: 2;
    position: relative;
}

.user-profile-image {
    .profile-img {
        width: 100%;
        min-height: 200px;

        img {
            object-fit: contain;
            border-radius: 18px;
            margin-bottom: 1em;
        }

        .asset-parent-wrapper {
            display: flex;
            justify-content: center;
        }

        .fa-user {
            display: block;
            text-align: center;
            font-size: 140px;
            padding: 15px;
            color: #ccc;
        }
    }
}

.tab-content {
    .top-customer-form {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }

    .top-customer-form-fields {
        width: 100%;

        .form-group {
            width: 100%;
            margin-bottom: 5px;
        }
    }

    .bottom-customer-form {
        .form-group {
            margin-bottom: 5px;

            label {
                display: inline-table;
            }
        }

        .multi-select {
            width: 100% !important;
        }

        .marketing,
        .privacy {
            margin-bottom: 5px;
            font-weight: 600;
            margin-top: 20px;

            span {
                display: block;
                font-size: 12px;
                color: #787878;
            }
        }
    }

    .customer-location div {
        width: 100%;
    }
}

.w100 {
    width: 100%;
}

.upload-image-preview-label {
    position: absolute;
    background: #ffffff94;
    padding: 20px;
    width: 250px;
    text-align: center;
    font-size: 25px;
    bottom: 0;
}

.product-variations {
    .product-variations-btn {
        border: 1px solid;
        background-color: #9bc4fc;
    }
}

.react-datetime-picker__wrapper,
.react-date-picker__wrapper {
    border: none !important;
}

.audio-track-selector-row {
    display: flex;
    justify-content: space-between;

    .multi-select {
        flex-grow: 1;
    }
}

.customer-page {
    .dashboard-product-card-label {
        padding-top: 0;
    }

    label {
        margin-bottom: 0;
    }

    .linked-purchased-products,
    .linked-favourited-products,
    .linked-addCart-products {
        margin-bottom: 25px;
        margin-right: 0;

        label {
            padding-bottom: 15px;
        }

        .dashboard-product-card-wrapper {
            label {
                padding-bottom: 0px;
            }
        }
    }

    .nav-tabs {
        a {
            i {
                padding-right: 2px;
            }
        }
    }

    .order-item {
        img {
            width: 100px;
            height: 100px;
            object-fit: contain;
            margin-right: 20px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .customer-view {
        .customer-page {
            .form-group {
                flex-direction: column;
            }
        }

        .card-body {
            padding: 0 10px 0 5px;
        }

        .customer-profile-top {
            flex-direction: column;
            align-items: center;
            margin-left: 0;

            .right {
                margin-left: 0px;
                margin-bottom: 80px;

                .tabs-container {
                    margin-left: 0;
                    margin-top: 0;
                }

                .crm-summary-header {
                    .crm-summary-header {
                        justify-content: flex-start;
                        align-items: inherit;
                    }

                    .crm-purchase-total {
                        position: absolute;
                        bottom: 5px;
                        right: 5px;
                    }

                    .crm-header-analytic-list {
                        padding: 0;

                        li {
                            padding: 10px 20px 10px 0px;
                        }
                    }
                }
            }
        }

        .tabs-container {
            .tab-content {
                min-height: 50vh;
                padding: 2px;
            }
        }

        .crm-summary-header {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: inherit;
        }

        .accordion {
            .card {
                margin-bottom: 4px;

                .card-header {
                    border-bottom: 0;
                }
            }
        }

        .crm-accordion-analytics {
            min-width: 100%;
            display: flex;
            flex-direction: column;
            white-space: pre;

            .crm-accordion-label {
                align-self: center;
                padding-bottom: 15px;
            }

            .crm-accordion-analytics-list {
                list-style: none;
                padding: 0;

                li {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding-bottom: 0px;
                    font-size: 14px;
                    line-height: 24px;

                    .left {
                        text-align: left;
                    }

                    .right {
                        text-align: right;
                    }
                }
            }
        }
    }

    .communications-board {
        .msg-board {
            .visits-mini-cards-wrapper {
                display: none;
            }
        }
    }

    .visits-mini-cards-wrapper {
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 780px) {
    .profile-page {
        flex-direction: column;

        .user-view-card {
            width: 100%;

            .created-updated-tag {
                margin-top: 10px;
            }
        }

        .user-view-settings {
            .user-view-settings-panel {
                margin-top: 15px;
                flex-direction: column;

                .teams {
                    .user-profile-tenant-card {
                        margin-right: 20px;
                    }
                }

                .user-disable-card {
                    height: auto;
                    padding: 3px;
                }

                &.timezone-locale {
                    .form-group {
                        &:last-child {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .timezone-locale {
        flex-direction: column;
    }

    .user-view-settings-panel {
        margin-top: 15px;
    }

    .user-profile-tenant-card {
        margin-right: 20px;
    }
}